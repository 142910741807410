/**
 * @author Irakli Kandelaki
 */

import { GetDataList } from "@/ts/interfaces/contentData.interface";
import fn from "@/helpers/func";
import { Params, IntervalParams, ProjectParams } from "@/ts/interfaces/params.interface";

let pageCounter = 1;
const loadData = async (params: Params): Promise<GetDataList> => {
    const { data } = await fn.postData("main/getDataList", params);
    return data;
};
const loadFlats = async (params: IntervalParams): Promise<GetDataList> => {
    const { data } = await fn.postData("dev/getFlats", params);
    return data;
};
const loadProjects = async (params: ProjectParams): Promise<GetDataList> => {
    const { data } = await fn.postData("dev/getProjects", params);
    return data;
};

export const lazyLoadHandler = async (
    isBottom: boolean,
    contentType: string,
    searchText: string
): Promise<GetDataList> => {
    if (!isBottom) return null;
    pageCounter += 1;
    const res = await loadData({
        contentType,
        searchText,
        pageNumber: pageCounter,
    });
    return res;
};

export const getFilteredData = async (
    contentType: string,
    searchText: string,
    searchTerms: number[] | null
): Promise<GetDataList> => {
    pageCounter = 1;
    const res = await loadData({
        contentType,
        searchText,
        searchTerms,
        pageNumber: pageCounter,
    });
    return res;
};

export const getFilteredIntervalData = async (
    group_by: string,
    price: number[],
    area: number[],
    rooms: number[],
    searchText: string,

): Promise<GetDataList> => {
    pageCounter = 1;
    let params: any = {
        pageNumber: pageCounter,
    };
    if (price && price.length) {
        params["price"] =  price;
    }
    if (area && area.length) {
        params["development_types_area_m2"] =  area;
    }
    if (rooms && rooms.length) {
        params["development_types_rooms"] =  rooms;
    }
    if (group_by) {
        params["group_by"] =  group_by;
    }
    if (searchText) {
        params["searchText"] = searchText;
    }
    const res = await loadFlats(params);
    return res;
};

export const getFilteredProjects = async (
    typeId: number | null,
    statusId: number | null,
    locationId: number | null,
    development_types_project_id: number[],

): Promise<GetDataList> => {
    pageCounter = 1;
    let params: any = {
        pageNumber: pageCounter,
    };
    if (typeId) {
        params["typeFilters"] = typeId;
    }
    if (statusId) {
        params["project_status"] = statusId;
    }
    if (locationId) {
        params["location"] = locationId;
    }
    if (development_types_project_id && development_types_project_id.length) {
        params["development_types_project_id"] =  development_types_project_id;
    };
    const res = await loadProjects(params);
    return res;
};

export const lazyLoadProjectsHandler = async (
    isBottom: boolean,
    typeId: number | null,
    statusId: number | null,
    locationId: number | null,
    development_types_project_id: number[],

): Promise<GetDataList> => {
    if (!isBottom) return null;
    pageCounter += 1;
    let params: any = {
        pageNumber: pageCounter,
    };
    if (typeId) {
        params["typeFilters"] = typeId;
    };
    if (statusId) {
        params["project_status"] = statusId;
    };
    if (locationId) {
        params["location"] = locationId;
    };
    if (development_types_project_id && development_types_project_id.length) {
        params["development_types_project_id"] =  development_types_project_id;
    };
    const res = await loadProjects(params);
    return res;
};